import { VENDOR_ORDERS, VENDOR_ORDERS_SHOW, VENDOR_ORDERS_ITEMS, VENDOR_ORDERS_PAYMENTS, VENDOR_ORDERS_TRANSITIONS } from "../endpoints";
import api from "../api";

export default {
    index(query) {
        let url = new URL(VENDOR_ORDERS);

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    show(vendorOrder = null) {
        if (vendorOrder === null) return;

        const url = VENDOR_ORDERS_SHOW.replace(":vendorOrder", vendorOrder);

        return api.get(url);
    },

    update(vendorOrder, data) {
        if (vendorOrder === null) return;

        const url = VENDOR_ORDERS_SHOW.replace(":vendorOrder", vendorOrder);

        return api.put(url, data);
    },

    itemsIndex(vendorOrder, query) {
        if (vendorOrder === null) return;

        let url = new URL(VENDOR_ORDERS_ITEMS.replace(":vendorOrder", vendorOrder));

        if (query !== null) {
            url.search = new URLSearchParams(query).toString();;
        }

        return api.get(url);
    },

    paymentsIndex(vendorOrder, query) {
        if (vendorOrder === null) return;

        let url = new URL(VENDOR_ORDERS_PAYMENTS.replace(":vendorOrder", vendorOrder));

        if (query !== null) {
            url.search = new URLSearchParams(query).toString();;
        }

        return api.get(url);
    },

    transitionsIndex(vendorOrder, query) {
        if (vendorOrder === null) return;

        let url = new URL(VENDOR_ORDERS_TRANSITIONS.replace(":vendorOrder", vendorOrder));

        if (query !== null) {
            url.search = new URLSearchParams(query).toString();;
        }

        return api.get(url);
    },
}