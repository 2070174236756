<template>
  <app-layout>
    <template v-slot:header> Comenzi vanzatori </template>
    <page-section>
      <page-section-body>
        <v-card>
          <loader v-if="loading" class="my-5" />
          <template v-else>
            <template v-if="totalRows">
              <vendor-orders-index-table :rows="rows" />
              <pagination
                @change:page="index($event)"
                :total="meta.total"
                :current_page="meta.current_page"
                :from="meta.from"
                :to="meta.to"
                :next_page_url="links.next"
                :prev_page_url="links.prev"
              />
            </template>
            <no-results v-else class="m-5" />
          </template>
        </v-card>
      </page-section-body>
    </page-section>
  </app-layout>
</template>

<script>
import vendorOrdersApi from "../api/resources/vendorOrdersApi";

import VendorOrdersIndexTable from "../components/VendorOrdersIndexTable.vue";
import VCard from "../components/ui/VCard.vue";
import AppLayout from "../layout/AppLayout.vue";

import Loader from "../components/Loader.vue";
import Pagination from "../components/Pagination.vue";
import NoResults from "../components/NoResults.vue";
import PageSection from '../components/ui/PageSection.vue';
import PageSectionBody from '../components/ui/PageSectionBody.vue';

export default {
  components: {
    VendorOrdersIndexTable,
    AppLayout,
    VCard,
    Loader,
    Pagination,
    NoResults,
    PageSection,
    PageSectionBody,
  },

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: true,
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    index(page = null) {
      this.loading = true;
      vendorOrdersApi
        .index(page && { page })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;
          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.index();
  },
};
</script>

<style></style>
