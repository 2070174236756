<template>
  <v-table>
    <v-thead>
      <tr>
        <v-table-th> # </v-table-th>
        <v-table-th>Data</v-table-th>
        <v-table-th>Client</v-table-th>
        <v-table-th>Email</v-table-th>
        <v-table-th>Comanda principala </v-table-th>
        <v-table-th>Vanzator </v-table-th>
        <v-table-th>Total </v-table-th>
        <v-table-th>Status</v-table-th>
      </tr>
    </v-thead>
    <v-tbody>
      <tr v-for="row in rows" :key="row.id">
        <v-table-td>
          <router-link
            :to="{
              name: 'vendorOrders.show',
              params: { id: row.id },
            }"
            class="text-yellow-600 hover:text-yellow-900"
          >
            {{ row.id }}
          </router-link>
        </v-table-td>
        <v-table-td>
          {{ new Date(row.created_at).toLocaleString() }}
        </v-table-td>
        <v-table-td>
          {{ row.billing_address?.name }}
        </v-table-td>
        <v-table-td>
          {{ row.order.email }}
        </v-table-td>
        <v-table-td>
          <div class="text-sm font-medium text-gray-900">
            ID: #{{ row.order.id }}
          </div>
          <div class="text-sm text-gray-500">
            Total {{ formatPrice(row.order.total) }}
          </div>
        </v-table-td>
        <v-table-td>
          {{ row.vendor?.name }}
        </v-table-td>
        <v-table-td>
          {{ formatPrice(row.total) }}
        </v-table-td>
        <v-table-td>
          {{ row.status.name.toUpperCase() }}
        </v-table-td>
      </tr>
    </v-tbody>
  </v-table>
</template>

<script>
import VTable from "./ui/VTable.vue";
import VTableTd from "./ui/VTableTd.vue";
import VTableTh from "./ui/VTableTh.vue";
import VTbody from "./ui/VTbody.vue";
import VThead from "./ui/VThead.vue";
import { priceFormatter } from "@/helpers";

export default {
  components: {
    VTableTd,
    VTable,
    VThead,
    VTableTh,
    VTbody,
  },

  props: {
    rows: {
      type: Array,
    },
  },

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
  },
};
</script>
